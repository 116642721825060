import React from "react";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import logo from "../images/sf_logo_250x250.png";
import { styled } from "@mui/material";

const Logo = styled("img")(({ theme }) => ({
  width: "50px",
  borderRadius: "50px",
  position: "absolute",
  top: "5px",
  left: "5px"
}));

export interface NavigationProps {}

export default function Navigation({
  children
}: React.PropsWithChildren<NavigationProps>) {
  return (
    <AppBar
      color="inherit"
      position="sticky"
      enableColorOnDark
      sx={{ backgroundColor: "gray.main" }}
    >
      <Logo src={logo} />
      <Container maxWidth="md">{children}</Container>
    </AppBar>
  );
}
