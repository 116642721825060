import React from "react";
import "./App.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import Copyright from "./ui/Copyright";
import Navigation from "./ui/Navigation";
import AboutUs from "./sections/AboutUs";
import Services from "./sections/Services";
import Contact from "./sections/Contact";
import NavLink from "./ui/NavLink";

const theme = createTheme({
  palette: {
    primary: {
      main: "#682866"
    },
    secondary: {
      main: "#8bb822"
    }
  }
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Navigation>
        <Toolbar>
          <NavLink to="about-us">About us</NavLink>
          <NavLink to="services">Services</NavLink>
          <NavLink to="contact">Contact</NavLink>
        </Toolbar>
      </Navigation>
      <header className="App-header">
        <Typography variant="h2" textTransform="uppercase">
          Solutions First
        </Typography>
        <Typography
          variant="subtitle1"
          color="palette.gray.main"
          textTransform="uppercase"
        >
          everything else Second
        </Typography>
        <Button variant="contained" sx={{ marginTop: "5vh" }}>
          Contact
        </Button>
      </header>
      <AboutUs />
      <Services />
      <Contact />
      <Copyright />
    </ThemeProvider>
  );
}

export default App;
