import React from "react";
import { styled } from "@mui/material";
import Section from "../ui/Section";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import services from "./services.json";

const Article = styled("article")(({ theme }) => ({
  width: "100%",
  position: "relative"
}));

const Header = styled("header")(`
  min-width: 50%;
`);

const P = styled("p")(({ theme }) => ({
  fontSize: "1.4rem",
  width: "100%",
  maxHeight: "parent"
}));

const Service = ({ service, idx }: any) => (
  <Article>
    <Header>
      <Typography variant="h3">
        {idx + 1}. {service.title}
      </Typography>
    </Header>
    <P>{service.description}</P>
  </Article>
);

export default styled(({ className }: any) => {
  return (
    <Section id="services" color={1} padding={10}>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4">Services</Typography>
          {services.map((service, idx) => (
            <Service service={service} idx={idx} />
          ))}
        </Grid>
      </Grid>
    </Section>
  );
})(``);
