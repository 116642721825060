import React from "react";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Section from "../ui/Section";

export default styled(({ className }: any) => {
  return (
    <Section id="contact" color={0} spacing={0}>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} md={4}>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .MuiFormControl-root": {
                width: "100%"
              },
              "& > *": {
                "& > *": {
                  width: "100%"
                },
                width: "100%"
              },
              "& > :not(style)": { m: 1, width: "25ch" }
            }}
            noValidate
          >
            <Typography variant="h4">Contact</Typography>
            <TextField
              fullWidth={true}
              id="name"
              label="Your Name"
              variant="outlined"
            />
            <TextField
              fullWidth={true}
              id="email"
              label="Email"
              variant="outlined"
            />
            <TextField
              fullWidth={true}
              id="message"
              multiline
              rows={8}
              label="Message"
              variant="outlined"
            />
            <Button variant="contained">Send</Button>
          </Box>
        </Grid>
      </Grid>
    </Section>
  );
})(``);
