import React from "react";
import { styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Section from "../ui/Section";

const Article = styled("article")(({ theme }) => ({
  width: "100%",
  padding: "10vh",
  backgroundColor: theme.palette.grey["900"],
  height: "cal(100%)",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  position: "relative"
}));

const Header = styled("header")(`
  min-width: 50%;
`);

const P = styled("p")(({ theme }) => ({
  fontSize: "1.4rem",
  width: "100%",
  maxHeight: "parent",
  color: "white"
}));

export default styled(({ className }: any) => {
  return (
    <Section id="about-us" color={0} spacing={0}>
      <Grid container spacing={0} direction="row" justifyContent="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4">About us</Typography>
          <Article>
            <Header>
              <Typography variant="h3" color="white">
                We provide Solutions
              </Typography>
              <Typography variant="h5" color="white">
                (hence the name)
              </Typography>
            </Header>
            <P color="white">
              Our main mission is to bring value to the client, no matter what
              form. We are invested in the idea process from start to finish
              with data to back it up. That's why we are focused on solutions,
              everything else comes second to us.
            </P>
          </Article>
        </Grid>
      </Grid>
    </Section>
  );
})(``);
