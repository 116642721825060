import React from "react";
import { styled } from "@mui/material";
import { Link } from "react-scroll";

export default styled(({ className, children, to }: any) => {
  return (
    <Link
      className={className}
      activeClass="active"
      to={to}
      spy={true}
      smooth={true}
      delay={200}
      duration={700}
    >
      {children}
    </Link>
  );
})(({ theme }) => ({
  padding: "1.5vh",
  textDecoration: "none",
  color: theme.palette.text.primary,
  margin: 0,
  fontSize: "1rem",
  lineHeight: 1.5,
  fontWeight: 400,
  cursor: "pointer",
  "&:hover": {
    borderTop: `4px solid ${theme.palette.primary.main}`,
    transition: "border-top 0.3s ease 0s"
  }
}));
