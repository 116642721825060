import * as React from "react";
import Typography from "@mui/material/Typography";

export default function Copyright() {
  return (
    <Typography
      sx={{
        display: "flex",
        minHeight: "5vh",
        justifyContent: "center",
        alignItems: "center"
      }}
      variant="body2"
      color="text.secondary"
      align="center"
    >
      {"Copyright © SolutionsFirst 2019"}
    </Typography>
  );
}
