import React from "react";
import { styled } from "@mui/material";

export interface SectionProps {
  color: number;
}
export default styled(({ id, className, title, children }: any) => {
  return (
    <main id={id} className={className}>
      <h2>{title}</h2>
      {children}
    </main>
  );
})(({ theme, color, spacing, padding }) => {
  const backgroundColor =
    color % 2 === 1 ? theme.palette.grey["300"] : theme.palette.grey["100"];
  const marginTop = spacing || 0;
  return {
    backgroundColor,
    width: "100%",
    minHeight: "88vh",
    marginTop: `${marginTop * 10}vh`,
    padding: `${(padding || 0) * 1.1}vh`,
    display: "flex",
    alignItems: "center"
  };
});
